export default function cms() {
    $('.cms:not(.js-cms-done)').each(function () {
        var wrapper = $(this);

        // tables
        wrapper.find('table:not(.js-cms-table-done)').wrap('<div class="cms-table" />')

        // done
        wrapper.addClass('js-cms-done');
    });
}
